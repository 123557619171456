import { Textarea, Button, VStack, useToast } from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'
import { useState, FC, useEffect, useRef } from 'react'
import Label from 'components/Label'
import { Unsubscribe } from 'firebase/firestore'
import { DBT, ModuleType } from 'types/internal'
import _ from 'lodash'
import TemperatureInput from 'components/TemperatureInput'
import JsonSchemeInput from 'components/JsonSchemeInput'
import NavBar from 'components/NavBar'
import { dbSubscribeToModule, dbUpdateModule } from 'controllers/modules'
import { useParams } from 'react-router'
import EmailVerificationModule from 'pages/module/EmailVerificationModule'

const emptyPrompt = {
  prompt: '',
  temperature: 0,
  maxTokens: 1500
}

const Module: FC = () => {
  const [conf, setConf] = useState<DBT.AIPromptConfT>(emptyPrompt)
  const [savedConf, setSavedConf] = useState<DBT.AIPromptConfT | null>(null)
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const toast = useToast()
  const { moduleType } = useParams() as { moduleType: ModuleType }

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    setConf(emptyPrompt)
    setSavedConf(null)
    unsubscribeRef.current = dbSubscribeToModule(moduleType, conf => {
      setConf(conf)
      setSavedConf(conf)
    })
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
    }
  }, [moduleType])

  const onSave = () => {
    if (conf) {
      try {
        const newConf = { ...conf }
        if (newConf.jsonScheme) {
          JSON.parse(newConf.jsonScheme)
        }
        dbUpdateModule(moduleType, conf)
      } catch (e) {
        toast({
          title: 'Error',
          description: 'Failed to save configuration, check JSON scheme',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const onChangePrompt = (prompt: string) => {
    if (conf) {
      setConf({ ...conf, prompt })
    }
  }

  const onJsonSchemeChange = (jsonScheme: string) => {
    if (conf) {
      setConf({ ...conf, jsonScheme })
    }
  }

  const renderTemperatureInput = () => {
    if (!conf) return null
    const value = conf.temperature || 0
    return (
      <TemperatureInput
        value={value}
        onChange={value => setConf({ ...conf, temperature: value })}
      />
    )
  }

  const actions = (
    <Button
      variant={'outline'}
      colorScheme='white'
      onClick={onSave}
      isDisabled={_.isEqual(conf, savedConf)}
      size='sm'
    >
      Save
    </Button>
  )

  const titleByModuleType = (moduleType: ModuleType) => {
    switch (moduleType) {
      case ModuleType.EmailVerification:
        return 'Email Verification'
      case ModuleType.SmsVerification:
        return 'SMS Verification'
      case ModuleType.IdentityVerification:
        return 'Identity Verification'
      case ModuleType.DocumentAuthentication:
        return 'Document Authentication'
      case ModuleType.AgeEstimation:
        return 'Age Estimation'
      case ModuleType.ProofOfIntent:
        return 'Proof of Intent'
      case ModuleType.KnowledgeVerify:
        return 'Knowledge Verify'
      default:
        return 'Unknown Module'
    }
  }

  const renderSubmoduleContent = () => {
    switch (moduleType) {
      case ModuleType.EmailVerification: {
        return <EmailVerificationModule conf={conf} setConf={setConf} />
        break
      }
      default: {
        return (
          <VStack w='full' spacing={6}>
            <VStack w='full' align={'flex-start'} spacing={2}>
              <Label>Prompt</Label>
              <Textarea
                placeholder='Enter prompt here'
                value={_.get(conf, 'prompt', '')}
                onChange={e => onChangePrompt(e.target.value)}
                fontSize={'sm'}
                minH='unset'
                overflow='hidden'
                w='100%'
                resize='none'
                minRows={5}
                as={ResizeTextarea}
              />
            </VStack>
            <JsonSchemeInput
              value={conf?.jsonScheme || ''}
              onChange={onJsonSchemeChange}
            />
            {renderTemperatureInput()}
          </VStack>
        )
      }
    }
  }

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={titleByModuleType(moduleType)} actions={actions} />
      <VStack w='full' overflow={'auto'} p={6}>
        {renderSubmoduleContent()}
      </VStack>
    </VStack>
  )
}

export default Module
