import {
  Box,
  HStack,
  Show,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = {
  title?: string
  actions?: ReactNode
  children?: ReactNode
}

const NavBar: FC<Props> = ({ title, actions, children }) => {
  const bgColor = useColorModeValue('zinc.900', 'white')
  const textColor = useColorModeValue('white', 'zinc.800')
  const borderColor = useColorModeValue('zinc.700', 'zinc.200')

  return (
    <VStack
      // h='14'
      p={4}
      w='full'
      justify={'space-between'}
      align='center'
      borderBottomWidth={1}
      borderBottomColor={borderColor}
      bg={bgColor}
      color={textColor}
    >
      <HStack w='full' justify={'space-between'} align='center'>
        {title && (
          <Box flex={1}>
            <Text size='lg' fontWeight={'bold'} pl={{ base: 8, lg: 0 }} whiteSpace='nowrap'>
              {title}
            </Text>
          </Box>
        )}
        <Show above='lg'>
          <HStack w='full' flex={2} justify={'center'}>
            {children}
          </HStack>
        </Show>
        <HStack flex={1} justify={'end'}>
          {actions}
        </HStack>
      </HStack>
      <Show below='lg'>
        <HStack w='full' flex={2} justify={'center'}>
          {children}
        </HStack>
      </Show>
    </VStack>
  )
}

export default NavBar
