import { useNavigate, useLocation } from 'react-router'
import {
  Box,
  BoxProps,
  VStack,
  Text,
  IconButton,
  Show,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react'
import { ArrowLeftIcon } from '@chakra-ui/icons'

import NavItem from 'components/NavItem'
import { dbSignOut } from 'controllers/auth'

interface LinkItem {
  name: string
  path: string
}

interface LinkItemProps {
  heading?: string
  items: LinkItem[]
}

const LinkItems: LinkItemProps[] = [
  {
    heading: 'Prompts',
    items: [
      { name: 'Demo 2', path: 'demo2' },
      { name: 'Two Factor Demo', path: 'two_factor' },
      { name: 'Flexible App', path: 'flex' },
      { name: 'Liveness Detection', path: 'liveness' },
      { name: 'Liveness Detection (Lite)', path: 'liveness_lite' },
      { name: 'Interrupt', path: 'interrupt' },
      { name: 'AI Analysis', path: 'analysis' }
    ]
  },
  {
    heading: 'Modules',
    items: [
      { name: 'Email Verification', path: 'module/emailVerification' },
      { name: 'SMS Verification', path: 'module/smsVerification' },
      { name: 'Identity Verification', path: 'module/identityVerification' },
      {
        name: 'Document Authentication',
        path: 'module/documentAuthentication'
      },
      { name: 'Age Estimation', path: 'module/ageEstimation' },
      { name: 'Proof of Intent', path: 'module/proofOfIntent' },
      { name: 'Knowledge Verify', path: 'module/knowledgeVerify' }
    ]
  },
  {
    heading: 'Tests',
    items: [
      { name: 'Demo 2', path: 'test/demo2' },
      { name: 'Liveness', path: 'test/liveness' }
    ]
  },
  {
    heading: 'Clients',
    items: [
      { name: 'Clients', path: 'clients' },
      { name: 'Recognized Users', path: 'users' }
    ]
  },
  {
    heading: 'Settings',
    items: [
      { name: 'Avatars list', path: 'settings/avatars' },
      { name: 'Two Factor Localization', path: 'tf_localization' },
      { name: 'Translater', path: 'translater' },
      { name: 'Langs', path: 'langs' }
    ]
  },
  {
    heading: 'Sessions Tables',
    items: [
      { name: 'Demo 2', path: 'sessions/demo2' },
      { name: 'Create Demo', path: 'sessions/create-demo' }
    ]
  }
]

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onItemClick = (path: string) => {
    navigate(path)
    onClose()
  }

  const renderItem = (item: LinkItem) => {
    const isSelected =
      item.path === 'sessions'
        ? location.pathname.startsWith('/session')
        : location.pathname === '/' + item.path
    return (
      <NavItem
        key={item.name}
        path={item.path}
        isSelected={isSelected}
        onClick={() => onItemClick(item.path)}
      >
        <Text fontSize={'sm'} textAlign={'start'}>
          {item.name}
        </Text>
      </NavItem>
    )
  }

  const determineDefaultIndex = () => {
    const res = LinkItems.findIndex(group =>
      group.items.some(item => location.pathname === '/' + item.path)
    )

    console.log('default index', res)
    return res
  }

  return (
    <VStack
      bg={'zinc.900'}
      borderRight='1px'
      borderRightColor={'zinc.700'}
      w={{ base: 'full', md: 'full' }}
      h='full'
      pt={4}
      overflow={'hidden'}
      align={'flex-start'}
      {...rest}
    >
      <Show below='lg'>
        <IconButton
          variant={'unstyled'}
          color='white'
          aria-label='close_menu'
          icon={<ArrowLeftIcon w={4} h={4} />}
          onClick={onClose}
        />
      </Show>
      <Box w='full' overflowY='auto' flex='1'>
        <Accordion
          defaultIndex={[determineDefaultIndex()]}
          allowMultiple
          w='full'
        >
          {LinkItems.map((links, index) => (
            <AccordionItem key={index} border='none'>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  <Text color='zinc.500' pl={0} fontWeight={'semibold'}>
                    {links.heading || 'Group'}
                  </Text>
                </Box>
                <AccordionIcon color='zinc.500' />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack align={'start'} spacing={0}>
                  {links.items.map(renderItem)}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
      <Box pb={2} pr={24}>
        <NavItem onClick={() => dbSignOut(navigate)}>Sign Out</NavItem>
      </Box>
    </VStack>
  )
}

export default SidebarContent
