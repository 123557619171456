import {
  collection,
  deleteField,
  doc,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { db } from 'controllers/db'
import { DBT } from 'types/internal'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'controllers/db'
import store from 'model/store'
import { receiveAvatars } from 'model/actions'
import { addListener } from 'controllers/listeners'

export const fetchAvatars = async () => {
  try {
    const ref = doc(collection(db, 'settings'), 'avatars')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const avatars = sn.data() as Record<string, DBT.AvatarT> | undefined
        if (avatars) {
          store.dispatch(receiveAvatars(avatars))
        }
      },
      err => {
        console.log(`fetchAvatars error: ${err.message}`)
      }
    )
    addListener('avatars', unsubscribe)
  } catch (e) {
    console.log('fetchLangs error', e)
  }
}

export const dbSyncHeyGenAvatars = async () => {
  try {
    console.log('dbSyncAvatars start')
    const f = httpsCallable(functions, 'fetchHeyGenAvatars')
    const res = await f()
    console.log('callable function res:', res)
    // return res.data as DBT.HGAvatarT[]
  } catch (e) {
    console.warn('error', e)
    return null
  }
}

export const dbUpdateAvatar = async (avatarId: string, isDisabled: boolean) => {
  try {
    console.log('dbUpdateAvatar', avatarId, isDisabled)
    const ref = doc(collection(db, 'settings'), 'avatars')
    await updateDoc(ref, { [`${avatarId}.isDisabled`]: isDisabled })
  } catch (e) {
    console.warn('error', e)
    return null
  }
}

export const dbSaveAvatar = async (avatar: DBT.AvatarT) => {
  try {
    console.log('dbSaveAvatar', avatar)
    const ref = doc(collection(db, 'settings'), 'avatars')
    await updateDoc(ref, { [`${avatar.id}`]: avatar })
    console.log(`Avatar with ID ${avatar.id} saved successfully.`)
  } catch (error) {
    console.error(`Error saving avatar with ID ${avatar.id}:`, error)
  }
}

export const dbDeleteAvatar = async (avatarId: string) => {
  try {
    console.log('dbDeleteAvatar', avatarId)
    const ref = doc(collection(db, 'settings'), 'avatars')
    await updateDoc(ref, { [`${avatarId}`]: deleteField() })
    console.log(`Avatar with ID ${avatarId} deleted successfully.`)
  } catch (error) {
    console.error(`Error deleting avatar with ID ${avatarId}:`, error)
  }
}
