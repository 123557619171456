import { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Image, IconButton } from '@chakra-ui/react'
import _ from 'lodash'

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/Dialog'

interface MediaItem {
  type: 'image' | 'video'
  src: string
  alt: string
}

interface MediaModalProps {
  isOpen: boolean
  onClose: () => void
  media: MediaItem[]
  initialIndex: number
}

export function MediaModal({ isOpen, onClose, media, initialIndex }: MediaModalProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  useEffect(() => {
    setCurrentIndex(initialIndex)
  }, [initialIndex])

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : media.length - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < media.length - 1 ? prevIndex + 1 : 0))
  }

  const filteredMedia = _.filter(media, m => m.src !== '')
  const currentItem = filteredMedia[currentIndex]

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent style={{
        maxWidth: '48rem',
        width: '100%',
        height: 'fit-content'
      }}>
        <DialogHeader>
          <DialogTitle>{currentItem?.alt}</DialogTitle>
          <DialogDescription>
            {`${currentIndex + 1} of ${filteredMedia.length}`}
          </DialogDescription>
        </DialogHeader>
        <Box h='100%' flex='1' flexGrow={0} display='flex' alignItems='center' justifyContent='center' position='relative'>
          {currentItem?.type === 'image' ? (
            <Image
              src={currentItem?.src}
              alt={currentItem?.alt}
              objectFit='contain'
            />
          ) : (
            <Box
              as='video'
              src={currentItem?.src}
              objectFit='contain'
              controls
            />
          )}
          <IconButton
            variant='ghost'
            size='sm'
            position='absolute'
            left='1rem'
            top='50%'
            transform='translateY(-50%)'
            onClick={handlePrevious}
            icon={<ChevronLeftIcon boxSize={4} />}
            aria-label='Previous'
          />
          <IconButton
            variant='ghost'
            size='sm'
            position='absolute'
            right='1rem'
            top='50%'
            transform='translateY(-50%)'
            onClick={handleNext}
            icon={<ChevronRightIcon boxSize={4} />}
            aria-label='Next'
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

