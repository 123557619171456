import { useEffect, useState, useMemo, ReactNode, memo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { ChevronLeftIcon, CopyIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Grid,
  Icon,
  Stack,
  VStack,
  Text,
  useColorModeValue,
  Flex,
  Spinner
} from '@chakra-ui/react'
import _ from 'lodash'
import ReactJson from 'react-json-view'
import { debounce } from 'lodash'


import NavBar from 'components/NavBar'
import { ILog } from 'types/back'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import { MediaModal } from 'pages/sessions/MediaModal'
import { getDemo2SessionById } from 'controllers/sessions'
import { TwoFactorT } from 'types/twoFactor'
import { getLogs } from 'controllers/twoFactor'

const SessionWrapper = ({ children }: { children: ReactNode }) => (
  <Box w='100%' h='100%'>
    {children}
  </Box>
)

const LogItem = memo(function LogItem({ log }: { log: ILog }) {
  const level = _.get(log, 'context.level.name', 'INFO')
  return (
    <Box
      p={3}
      borderRadius='lg'
      fontSize='sm'
      width='100%'
      sx={{
        bg: {
          ERROR: 'red.50',
          WARN: 'yellow.50',
          DEBUG: 'gray.50',
          INFO: 'blue.50'
        }[level],
        color: {
          ERROR: 'red.900',
          DEBUG: 'gray.900',
          WARN: 'yellow.900',
          INFO: 'blue.900'
        }[level]
      }}
    >
      <VStack align='start' spacing={2} width='100%'>
        <Text fontWeight='medium' fontSize='sm' fontFamily='mono'>
          {new Date(log.timestamp).toLocaleString()} [{level}]
        </Text>

        {log.messages && (
          <VStack w='full' align='flex-start' spacing={2}>
            {_.map(log.messages, ((message, index) => (
              <LogMessage
                key={`${log.timestamp}_${index}`}
                message={message}
                level={level}
              />
            )))}
          </VStack>
        )}
      </VStack>
    </Box>
  )
}, (prev: { log: ILog }, next: { log: ILog }) => _.isEqual(prev.log, next.log))

const LogMessage = memo(function LogMessage({ message, level }: { message: string | object; level: string }) {
  if (_.isObject(message)) {
    return (
      <Box width='100%' borderRadius='md' p={2}>
        <ReactJson
          src={message}
          collapsed={1}
          displayDataTypes={false}
          quotesOnKeys={false}
          style={{
            fontFamily: 'monospace',
            fontSize: '12px',
            lineHeight: '1.2',
            wordBreak: 'break-word'
          }}
        />
      </Box>
    )
  }

  return (
    <Text
      fontFamily='monospace'
      fontSize='sm'
      whiteSpace='pre-wrap'
      wordBreak='break-word'
      overflowWrap='anywhere'
      lineHeight='short'
      color={{
        ERROR: 'red.900',
        DEBUG: 'gray.900',
        WARN: 'yellow.900',
        INFO: 'blue.900'
      }[level]}
    >
      {message}
    </Text>
  )
})

export const LogsViewer = memo(function LogsViewer({
  logs,
  isExpanded,
  onToggle,
  systemLogsSearch
}: {
  logs: ILog[] | null
  isExpanded: boolean
  onToggle: () => void
  systemLogsSearch: string
}) {
  if (_.isNil(logs)) {
    return (
      <Flex position='relative' justify='center' align='center' h='full'>
        <Spinner />
      </Flex>
    )
  }

  const deepSearch = (obj: object | string): boolean => {
    const searchTerm = systemLogsSearch.toLowerCase()
    if (_.isString(obj)) {
      return obj.toLowerCase().includes(searchTerm)
    }

    if (_.isArray(obj)) {
      return obj.some(item => deepSearch(item))
    }

    if (_.isObject(obj)) {
      return _.values(obj).some(value => deepSearch(value))
    }

    return false
  }

  const filteredLogs = logs.filter(log => {
    try {
      return deepSearch(log.messages) || deepSearch(log.context)
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error filtering logs:', e)
      }
      return false
    }
  })

  return (
    <Box position='relative'>
      <VStack
        spacing={3}
        overflowY={isExpanded ? 'auto' : 'hidden'}
        transition='all 0.3s'
        maxH={isExpanded ? '600px' : '350px'}
        align='start'
        width='100%'
      >
        {filteredLogs.map((log, index) => (
          <LogItem key={`${log.timestamp}_${index}`} log={log} />
        ))}
      </VStack>
      {!isExpanded && (
        <Box
          position='absolute'
          bottom={0}
          left={0}
          right={0}
          h='100px'
          bgGradient='linear(to-t, white, transparent)'
          pointerEvents='none'
        />
      )}

      <Button
        variant='outline'
        onClick={onToggle}
        mt={4}
        width='full'
      >
        {isExpanded ? (
          <>
            <Icon as={ChevronUpIcon} mr={2} /> Collapse
          </>
        ) : (
          <>
            <Icon as={ChevronDownIcon} mr={2} /> Expand
          </>
        )}
      </Button>
    </Box>
  )
})


export default function Demo2SessionDetails() {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const [session, setSession] = useState<TwoFactorT.RecapT>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [isSystemLogsExpanded, setIsSystemLogsExpanded] = useState(false)
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number | null>(null)
  const [transcriptSearch, setTranscriptSearch] = useState('')
  const [systemLogsSearch, setSystemLogsSearch] = useState('')
  const labelColor = useColorModeValue('gray.500', 'gray.400')
  const [logs, setLogs] = useState<ILog[] | null>(null)
  const [logsError, setLogsError] = useState<string | null>(null)

  const errorLogs = useMemo(() => {
    return _.filter(logs, v => _.get(v, 'context.level.name') === 'ERROR')
  }, [logs])

  const debouncedSetSystemLogsSearch = useCallback(
    debounce((value) => setSystemLogsSearch(value), 500),
    []
  )

  useEffect(() => {
    return () => debouncedSetSystemLogsSearch.cancel()
  }, [debouncedSetSystemLogsSearch])

  useEffect(() => {
    const run = async () => {
      if (!sessionId) return
      setLoading(true)
      const res = await getDemo2SessionById(sessionId)
      setSession(res)
      setLoading(false)
      const logsResponse = await getLogs(sessionId)
      if (!_.has(logsResponse, 'error')) {
        setLogs((logsResponse ?? []) as ILog[])
      } else {
        setLogsError(logsResponse.error)
      }
    }
    if (sessionId && _.isNil(session)) {
      run()
    }
  }, [sessionId])

  const filteredTranscript = useMemo(() => session?.steps.filter((entry): entry is TwoFactorT.StepT & { text: string } =>
    'text' in entry &&
    entry.text.toLowerCase().includes(transcriptSearch.toLowerCase())
  ), [sessionId, session, transcriptSearch])

  const facialAnalysis = useMemo(() => (
    `${_.get(session, 'aiAnalysisFirst.ageMin') ?? '?'} –
    ${_.get(session, 'aiAnalysisFirst.ageMax') ?? '?'} years old,
    ${_.get(session, 'aiAnalysisFirst.sex')}`
  ), [sessionId, session])

  const latencyMetrics = useMemo(() => {
    let apiCallLatency = 0
    let speechRecognitionLatency = 0
    let faceRecognitionLatency = 0

    session?.steps.forEach((item) => {
      if (item.sType === TwoFactorT.StepTypeT.QUESTION) {
        const heyGen = item.heyGenLatency ?? 0
        const aiTop = item.aiLatency ?? 0
        const backend = item.backendLatencies || {}
        const replyAnalysis = backend.replyAnalysisLatency ?? 0
        const aiBackend = backend.aiLatency ?? 0
        const dbLatency = backend.dbLatency ?? 0
        const aiToolLatency = backend.aiToolLatency ?? 0

        apiCallLatency += heyGen + dbLatency + aiTop
        speechRecognitionLatency += replyAnalysis
        faceRecognitionLatency += aiBackend + aiToolLatency
      } else if (item.sType === TwoFactorT.StepTypeT.REPLY) {
        const deepgram = item.deepgramLatency ?? 0
        speechRecognitionLatency += deepgram
      }
    })

    const totalLatency = apiCallLatency + speechRecognitionLatency + faceRecognitionLatency

    return {
      totalLatency,
      apiCallLatency,
      speechRecognitionLatency,
      faceRecognitionLatency
    }
  }, [sessionId, session, loading])

  const sessionDuration = useMemo(() => {
    if (!_.isNil(logsError)) return 'Error'
    if (_.isNil(logs)) return 'Loading'

    const durationSec = Math.floor((logs[logs.length - 1].timestamp - logs[0].timestamp) / 1000)

    const h = Math.floor(durationSec / 3600)
    const m = Math.floor((durationSec % 3600) / 60)
    const s = durationSec % 60

    return h > 0 ? `${h}h ${m}m ${s}s` : m > 0 ? `${m}m ${s}s` : `${s}s`
  }, [logs, logsError])



  if (!sessionId) {
    return null
  }

  if (loading || !session) {
    return (
      <SessionWrapper>
        <NavBar title={`Session Details: ${sessionId}`} actions={
          <Button onClick={() => navigate(-1)} gap='2'>
            <ChevronLeftIcon /> Back to Sessions
          </Button>
        } />
        <Flex align='center' justify='center' h='90%'>
          <Spinner size='xl' />
        </Flex>
      </SessionWrapper>
    )
  }

  const mediaItems = [
    { type: 'image' as const, src: session.userAvatarUrl ?? '', alt: 'User Screenshot' },
    { type: 'video' as const, src: session.userVideoUrl ?? '', alt: 'User Video' },
    { type: 'video' as const, src: session.avatarVideoUrl ?? '', alt: 'Avatar Video' }
  ]

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(session, null, 2))
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }


  return (
    <SessionWrapper>
      <NavBar title={`Session Details: ${session.id}`} actions={
        <Button onClick={() => navigate(-1)} gap='2'>
          <ChevronLeftIcon /> Back to Sessions
        </Button>
      } />
      <Box maxW='container.xl' mx='auto' py={10} h='fit-content'>
        <Stack spacing={6}>
          <Card>
            <CardHeader pb='2'>
              <Heading size='md'>Session Summary</Heading>
            </CardHeader>
            <CardBody>
              <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap='6'>
                {[
                  { label: 'Facial analysis', value: facialAnalysis },
                  { label: 'Liveness detection', value: _.get(session, 'livenessDetected', false) ? 'Yes' : 'No' },
                  { label: 'Location', value: `${_.get(session, 'location.city.names.en', '-')}, ${_.get(session, 'location.country.names.en', '-')}` },
                  { label: 'Device', value: `${_.get(session, 'deviceDetails.platform', '-')}, ${_.get(session, 'deviceDetails.browser', '-')}` },
                  { label: 'Session Duration', value: sessionDuration },
                  { label: 'Language', value: _.get(session, 'lang', '-') },
                  { label: 'Interaction ID', value: _.get(session, 'id') },
                  { label: 'OS', value: _.get(session, 'deviceDetails.os', '-') },
                  { label: 'Browser Version', value: `${_.get(session, 'deviceDetails.browser', '-')}, ${_.get(session, 'deviceDetails.version', '-')}` },
                  { label: 'New User', value: !_.get(session, 'isNotNew') ? 'Yes' : 'No' },
                  { label: 'Deepfake', value: !_.get(session, 'livenessDetected') ? 'Yes' : 'No' },
                  { label: 'Live Person', value: _.get(session, 'livenessDetected') ? 'Yes' : 'No' },
                  { label: 'Age Range', value: `${_.get(session, 'aiAnalysisFirst.ageMin') ?? '?'} – ${_.get(session, 'aiAnalysisFirst.ageMax') ?? '?'}` },
                  { label: 'Session Completed', value: _.get(session, 'completed') ? 'Yes' : 'No' }
                ].map(({ label, value }, idx) => (
                  <VStack key={idx} spacing='1' align='flex-start'>
                    <Text fontSize='sm' fontWeight='semibold' color={labelColor}>
                      {label}
                    </Text>
                    <Text fontSize='lg'>{value}</Text>
                  </VStack>
                ))}
              </Grid>
            </CardBody>
          </Card>
          <Card>
            <CardHeader pb={2}>
              <Heading size='md'>Latency Metrics</Heading>
            </CardHeader>
            <CardBody>
              <Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
                <VStack align='start' spacing={1}>
                  <Text fontSize='sm' fontWeight='semibold' color='gray.500'>
                    Total Latency
                  </Text>
                  <Text fontSize='lg'>{latencyMetrics.totalLatency} ms</Text>
                </VStack>
                <VStack align='start' spacing={1}>
                  <Text fontSize='sm' fontWeight='semibold' color='gray.500'>
                    API Call Latency
                  </Text>
                  <Text fontSize='lg'>{latencyMetrics.apiCallLatency} ms</Text>
                </VStack>
                <VStack align='start' spacing={1}>
                  <Text fontSize='sm' fontWeight='semibold' color='gray.500'>
                    Speech Recognition Latency
                  </Text>
                  <Text fontSize='lg'>{latencyMetrics.speechRecognitionLatency} ms</Text>
                </VStack>
                <VStack align='start' spacing={1}>
                  <Text fontSize='sm' fontWeight='semibold' color='gray.500'>
                    Face Recognition Latency
                  </Text>
                  <Text fontSize='lg'>{latencyMetrics.faceRecognitionLatency} ms</Text>
                </VStack>
              </Grid>
            </CardBody>
          </Card>

          <Card>
            <CardHeader pb='2'>
              <Heading size='md'>AI Analysis</Heading>
            </CardHeader>
            <CardBody>
              <Grid templateColumns={{ base: '1fr', sm: 'repeat(3, 1fr)' }} gap={4} mb={20}>
                {mediaItems.map((item, index) => (
                  <Box key={index}>
                    <Text fontSize='sm' fontWeight='semibold' color='gray.500' mb={2}>
                      {item.alt}
                    </Text>
                    <Box
                      as={item.type === 'image' ? 'img' : 'video'}
                      src={item.src}
                      height='100%'
                      borderRadius='lg'
                      cursor='pointer'
                      bg='black'
                      objectFit='contain'
                      onClick={() => setSelectedMediaIndex(index)}
                      {...(item.type === 'video' && { controls: true })}
                    />
                  </Box>
                ))}
              </Grid>
              <Stack spacing={4}>
                {Object.entries(session.aiAnalysisFirst?.analysis ?? []).map(([key, value]) => (
                  key !== 'facial' && key !== 'liveness' && key !== 'device' && (
                    <Box key={key}>
                      <VStack align='stretch' spacing={2}>
                        <Heading
                          as='h3'
                          fontSize='sm'
                          fontWeight='semibold'
                          color='gray.500'
                          textTransform='capitalize'
                        >
                          {value.title.replace(/([A-Z])/g, ' $1').trim()}
                        </Heading>

                        <Box
                          bg='gray.50'
                          borderRadius='lg'
                          p={3}
                          fontSize='sm'
                        >
                          <Text whiteSpace='pre-wrap'>{value.shortDescription}</Text>
                          <Text whiteSpace='pre-wrap' pt='4'>
                            DETAILED ANALYSIS
                          </Text>
                          <Text whiteSpace='pre-wrap'>{value.longDescription}</Text>
                        </Box>
                      </VStack>
                    </Box>
                  )
                ))}
              </Stack>
            </CardBody>
          </Card>
        </Stack>

        <Grid
          mt={6}
          templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          templateRows='auto'
          gap={6}
          alignItems='stretch'
        >
          <Card>
            <CardHeader pb='2'>
              <Heading size='md'>Transcript</Heading>
            </CardHeader>
            <CardBody>
              <Box mb={4}>
                <Input
                  type='text'
                  placeholder='Search transcript...'
                  defaultValue={transcriptSearch}
                  onChange={(e) => setTranscriptSearch(e.target.value)}
                  w='100%'
                />
              </Box>
              <Stack maxH={600} overflowY='auto' spacing={2}>
                {filteredTranscript?.map((entry, index) => (
                  <Box
                    key={index}
                    p='2'
                    borderRadius='lg'
                    bg={entry.sType === TwoFactorT.StepTypeT.QUESTION ? 'blue.50' : 'gray.50'}
                    color={entry.sType === TwoFactorT.StepTypeT.QUESTION ? 'blue.900' : undefined}
                  >
                    <Text as='span' fontWeight='medium'>
                      {entry.sType === TwoFactorT.StepTypeT.QUESTION ? 'FACESIGN' : 'USER'}:
                    </Text>
                    <Text as='span' ml='1'>
                      {entry.text}
                    </Text>
                    {/* <Text as='span' fontSize='xs' ml='2'>
                      {new Date(entry.timestamp).toLocaleTimeString()}
                    </Text> */}
                  </Box>
                ))}
              </Stack>
            </CardBody>
          </Card>

          <Card>
            <CardHeader pb={2}>
              <Heading size='md'>System Logs</Heading>
            </CardHeader>
            <CardBody>
              <Box mb={4}>
                <Input
                  placeholder='Search system logs...'
                  defaultValue={systemLogsSearch}
                  onChange={(e) => debouncedSetSystemLogsSearch(e.target.value)}
                  width='full'
                  isDisabled={logs === null}
                />
              </Box>
              {logsError ? (
                <Text>Error while fetching logs: {logsError}</Text>
              ) : (
                <LogsViewer
                  logs={logs}
                  isExpanded={isSystemLogsExpanded}
                  onToggle={() => setIsSystemLogsExpanded(!isSystemLogsExpanded)}
                  systemLogsSearch={systemLogsSearch}
                />
              )}
            </CardBody>
          </Card>
        </Grid>

        <Card gridColumn={{ md: 'span 2' }} mt={6}>
          <CardHeader pb={2}>
            <Heading size='md'>Error Handling</Heading>
          </CardHeader>
          <CardBody>
            {_.isNil(logs) && !logsError ? (
              <Flex w='full' h='20' justify='center' align='center'>
                <Spinner />
              </Flex>
            ) : errorLogs.length > 0 ? (
              <Stack spacing={2}>
                {errorLogs.map((error, index) => (
                  <Box key={index} bg='red.50' color='red.900' p={2} borderRadius='md'>
                    <Text as='span' fontWeight='medium'>
                      {new Date(error.timestamp).toLocaleString()}:{' '}
                    </Text>
                    {error.messages[0]}
                  </Box>
                ))}
              </Stack>
            ) : logsError ? (
              <Text>Error while fetching logs: {logsError}</Text>
            ) : (
              <Text>No errors occurred during this session.</Text>
            )}
          </CardBody>
        </Card>

        <Card gridColumn={{ md: 'span 2' }} mt={6}>
          <CardHeader pb={2}>
            <Heading size='md'>Raw Session Details</Heading>
          </CardHeader>
          <CardBody>
            <Box position='relative'>
              <Button
                variant='outline'
                onClick={copyToClipboard}
                position='absolute'
                top={2}
                right={2}
                zIndex={10}
                leftIcon={<CopyIcon />}
              >
                {isCopied ? 'Copied!' : 'Copy'}
              </Button>
              <Box position='relative'>
                <Box
                  borderRadius='lg'
                  overflow='auto'
                  transition='all 0.3s'
                  maxH={isExpanded ? '600px' : '200px'}
                >
                  <SyntaxHighlighter language='json' style={tomorrow}>
                    {JSON.stringify(session, null, 2)}
                  </SyntaxHighlighter>
                </Box>
                <Button
                  variant='outline'
                  onClick={() => setIsExpanded(!isExpanded)}
                  position='absolute'
                  bottom={2}
                  left='50%'
                  transform='translateX(-50%)'
                  leftIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Button>
              </Box>
            </Box>
          </CardBody>
        </Card>
        <MediaModal
          isOpen={selectedMediaIndex !== null}
          onClose={() => setSelectedMediaIndex(null)}
          media={mediaItems}
          initialIndex={selectedMediaIndex || 0}
        />
      </Box>
    </SessionWrapper>
  )
}

