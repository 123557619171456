import { useState, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Column,
  Row,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState
} from '@tanstack/react-table'
import { SearchIcon, ArrowUpDownIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  HStack,
  Text,
  IconButton,
  Tag,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton
} from '@chakra-ui/react'
import { DateRange } from 'react-day-picker'
import _ from 'lodash'

import NavBar from 'components/NavBar'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import { DateRangePicker } from 'pages/sessions/DateRangePicker'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { DraggableColumnList } from 'pages/sessions/DraggableColumnList'
import { FaceSignSession, DEFAULT_FILTER_OPTIONS, DEFAULT_SELECTED_FILTER_OPTIONS } from 'pages/CreateDemoTable'
import { ColumnFilterDropdown } from 'pages/sessions/ColumnFilterDropdown'
import { SessionStatus } from 'types/internal'

const colors = ['blue', 'gray', 'yellow', 'cyan', 'green', 'purple', 'orange', 'pink', 'teal', 'red']

const sessionStatusesColorMap = Object.fromEntries(Object.values(SessionStatus).map((status, i) => [status, colors[(i + 2) % colors.length]]))

export function CreateDemoSessionsTable({
  data,
  loading,
  setDateRange,
  pageSize,
  filterValue,
  setFilterValue,
  sortingSettings,
  handleSortClick,
  filterOptions,
  handleFilterChange,
  handleFilterReset
}: {
  data: FaceSignSession[]
  loading: boolean
  setDateRange: (v: DateRange) => void
  pageSize: number
  filterValue: string
  setFilterValue: (v: string) => void
  sortingSettings: {
    id: 'dateTime' | 'email' | 'userName',
    desc: boolean
  },
  handleSortClick: (v: 'dateTime' | 'email' | 'userName') => void
  filterOptions: { [key: string]: Set<string> },
  handleFilterChange: (v: { id: string, selectedValues: Set<string> }) => void,
  handleFilterReset: () => void
}) {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnOrder, setColumnOrder] = useState<string[]>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize
  })

  const browserColorMap = useMemo(() => {
    const map: { [key: string]: string } = {}
    const browserFilter = DEFAULT_FILTER_OPTIONS.browser as Set<string>
    [...browserFilter].forEach((browser, index) => {
      _.set(map, browser, colors[index + 2 % colors.length])
    })
    return map
  }, [DEFAULT_FILTER_OPTIONS])


  const platformColorMap = useMemo(() => {
    const map: { [key: string]: string } = {}
    const platformsFilter = DEFAULT_FILTER_OPTIONS.platform as Set<string>
    [...platformsFilter].forEach((platform, index) => {
      _.set(map, platform, colors[index % colors.length])
    })
    return map
  }, [DEFAULT_FILTER_OPTIONS])

  useEffect(() => {
    setPagination(prev => ({
      pageIndex: prev.pageIndex,
      pageSize: pageSize
    }))
  }, [pageSize, loading])

  const columns = useCallback(() => [
    {
      accessorKey: 'sessionId',
      header: () => (
        <HStack align='center' minW='11rem'>
          <Text fontWeight='medium'>Session ID</Text>
        </HStack>
      )
    },
    {
      accessorKey: 'dateTime',
      header: () => useMemo(() => (
        <HStack align='center'>
          <Text fontWeight='medium'>
            Date/Time
          </Text>
          <IconButton
            variant='ghost'
            size='sm'
            aria-label='Sort'
            icon={<ArrowUpDownIcon boxSize={4} />}
            color={sortingSettings.id === 'dateTime' ? 'blue.600' : 'gray.500'}
            onClick={() => handleSortClick('dateTime')}
          />
        </HStack>
      ), [sortingSettings])
    },
    {
      accessorKey: 'email',
      header: () => useMemo(() => (
        <HStack align='center'>
          <Text fontWeight='medium'>Email</Text>
        </HStack>
      ), [sortingSettings])
    },
    {
      accessorKey: 'userName',
      header: () => useMemo(() => (
        <HStack align='center'>
          <Text fontWeight='medium'>Name</Text>
        </HStack>
      ), [sortingSettings])
    },

    {
      accessorKey: 'platform',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => useMemo(() => (
        (
          <HStack spacing={2} justify='space-between' alignItems='center' >
            <Text fontWeight='medium'>Platform</Text>
            <ColumnFilterDropdown
              column={column}
              options={DEFAULT_FILTER_OPTIONS.platform}
              selectedOptions={filterOptions.platform}
              colorMap={platformColorMap}
              handleFilterChange={handleFilterChange}
            />
          </HStack >
        )
      ), [DEFAULT_FILTER_OPTIONS]),
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const platform = row.original.platform as string
        return (
          <Tag
            size='sm'
            bg={platformColorMap[platform] ? `${platformColorMap[platform]}.100` : 'blue.100'}
            color={platformColorMap[platform] ? `${platformColorMap[platform]}.800` : 'blue.800'}
            borderRadius='full'
            px={2}
            py={1}
            fontSize='xs'
            fontWeight='semibold'
          >
            {platform}
          </Tag>
        )
      },
      filterFn: (row: { getValue: (arg0: string) => string }, id: string, filterValues: string | string[]) => {
        if (!filterValues || filterValues.length === 0) return true
        return filterValues.includes(row.getValue(id))
      }
    },
    {
      accessorKey: 'browser',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => useMemo(() => (
        <HStack spacing={2} justify='space-between' alignItems='center'>
          <Text fontWeight='medium'>Browser</Text>
          <ColumnFilterDropdown
            column={column}
            options={DEFAULT_FILTER_OPTIONS.browser}
            selectedOptions={filterOptions.browser}
            colorMap={browserColorMap}
            handleFilterChange={handleFilterChange}
          />
        </HStack>
      ), [DEFAULT_FILTER_OPTIONS]),
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const browser = row.original.browser
        return (
          <Tag
            size='sm'
            bg={browserColorMap[browser] ? `${browserColorMap[browser]}.100` : 'yellow.100'}
            color={browserColorMap[browser] ? `${browserColorMap[browser]}.800` : 'yellow.800'}
            borderRadius='full'
            px={2}
            py={1}
            fontSize='xs'
            fontWeight='semibold'
          >
            {browser}
          </Tag>
        )
      }
    },
    {
      accessorKey: 'avatar',
      header: () => (
        <HStack justify='space-between' align='center'>
          <Text fontWeight='medium'>Avatar</Text>
        </HStack>

      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => (
        <Text
          px={2}
          py={1}
          borderRadius='full'
          bg='purple.100'
          color='purple.800'
          fontSize='xs'
          fontWeight='semibold'
          textAlign='center'
        >
          {row.original.avatar}
        </Text>
      )
    },
    {
      accessorKey: 'status',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => useMemo(() => (
        <HStack justify='space-between' align='center'>
          <Text fontWeight='medium'>Status</Text>
          <ColumnFilterDropdown
            column={column}
            options={DEFAULT_FILTER_OPTIONS.status}
            selectedOptions={filterOptions.status}
            colorMap={sessionStatusesColorMap}
            handleFilterChange={handleFilterChange}
          />
        </HStack>
      ), [DEFAULT_FILTER_OPTIONS]),
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const status = row.original.status ?? ''
        return (
          <Tag
            size='sm'
            bg={sessionStatusesColorMap[status] ? `${sessionStatusesColorMap[status]}.100` : 'yellow.100'}
            color={sessionStatusesColorMap[status] ? `${sessionStatusesColorMap[status]}.800` : 'yellow.800'}
            borderRadius='full'
            px={2}
            py={1}
            fontSize='xs'
            fontWeight='semibold'
          >
            {status.replace(/([A-Z])/g, ' $1').trim().replace(/^./, c => c.toUpperCase())}
          </Tag>
        )
      }
    },
    {
      accessorKey: 'apiVersion',
      header: 'Api Version'
    },
    {
      accessorKey: 'verificationId',
      header: () => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Verification ID</Text>
        </HStack>
      )
    },
    {
      accessorKey: 'userId',
      header: () => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>User ID</Text>
        </HStack>
      )
    },
    {
      accessorKey: 'location',
      header: 'Location'
    },
    {
      accessorKey: 'language',
      header: 'Language'
    },
    {
      accessorKey: 'photoThumbnail',
      header: () => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Photo Thumbnail</Text>
        </HStack>
      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => (
        row.original.photoThumbnail ? (
          <Image
            src={row.original.photoThumbnail}
            alt='Thumbnail'
            boxSize='30px'
            objectFit='cover'
            borderRadius='full'
          />
        ) : (
          <Avatar boxSize='30px' position='relative' zIndex={1} />
        )
      )
    },
    {
      accessorKey: 'ageRange',
      header: 'Age Range'
    },
    {
      accessorKey: 'isNewUser',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>New User</Text>
          <ColumnFilterDropdown
            column={column}
            options={DEFAULT_FILTER_OPTIONS.isNewUser}
            selectedOptions={filterOptions.isNewUser}
            handleFilterChange={handleFilterChange}
          />
        </HStack>
      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => (row.original.isNewUser === null ? '?' : row.original.isNewUser ? 'Yes' : 'No')
    },
    {
      accessorKey: 'isLivePerson',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Live Person</Text>
          <ColumnFilterDropdown
            column={column}
            options={DEFAULT_FILTER_OPTIONS.isLivePerson}
            selectedOptions={filterOptions.isLivePerson}
            handleFilterChange={handleFilterChange}
          />
        </HStack>
      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => (row.original.isLivePerson === null ? '?' : row.original.isLivePerson ? 'Yes' : 'No')
    },
    {
      accessorKey: 'sessionCompleted',
      header: () => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Session Completed</Text>
        </HStack>
      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const isCompleted = row.original.sessionCompleted
        return (
          <Tag size='sm' colorScheme={isCompleted ? 'green' : 'red'} borderRadius='full'>
            {isCompleted ? 'Yes' : 'No'}
          </Tag>
        )
      }
    }
  ], [data, loading, sortingSettings])

  const memoizedColumns = useMemo(() => columns(), [columns, sortingSettings])
  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    setColumnVisibility(memoizedColumns.reduce((res, v) => {
      res = { ...res, [v.accessorKey]: true }
      return res
    }, {}))
  }, [memoizedColumns])

  const handleColumnVisibilityChange = (columnId: string, isVisible: boolean) => {
    setColumnVisibility(prev => {
      const res = { ...prev }
      _.set(res, columnId, isVisible)
      return res
    })

    table.setColumnVisibility(prev => ({
      ...prev,
      [columnId]: isVisible
    }))
  }

  const handleAllColumnVisibilityChange = (variant: 'clear' | 'select') => {
    setColumnVisibility(memoizedColumns.reduce((res, v) => {
      res = { ...res, [v.accessorKey]: variant === 'select' }
      return res
    }, {}))
  }

  const handleColumnOrderChange = (newOrder: string[]) => {
    setColumnOrder(newOrder)
    table.setColumnOrder(newOrder)
  }

  const table = useReactTable({
    data,
    columns: useMemo(
      () =>
        loading
          ? memoizedColumns.map((column) => ({
            ...column,
            cell: () => <Skeleton height='10px' width='100%' />
          }))
          : memoizedColumns,
      [loading, memoizedColumns]
    ),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onPaginationChange: setPagination,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
      pagination
    },
    initialState: {
      pagination: {
        pageSize
      }
    }
  })

  const navbarActions = useMemo(() => (
    <Box display='flex' alignItems='center' gap='4' zIndex={50}>
      {!_.isEqual(DEFAULT_SELECTED_FILTER_OPTIONS, filterOptions) && (
        <Box position='relative' flex='1' maxW='16rem'>
          <Button variant='outline' onClick={handleFilterReset}>
            Clear all filters
          </Button>
        </Box>
      )}
      <Box position='relative' flex='1' maxW='16rem' minW='14rem'>
        <SearchIcon
          position='absolute'
          left='3'
          top='50%'
          transform='translateY(-50%)'
          color='gray.400'
          zIndex='10'
        />
        <Input
          id='global-search'
          defaultValue={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value)
          }}
          placeholder='Search all columns...'
          color='gray.400'
          pl='10'
          w='100%'
        />
      </Box>
      <DateRangePicker onDateRangeChange={setDateRange} />
      <Popover>
        <PopoverTrigger>
          <Button variant='outline'>Columns</Button>
        </PopoverTrigger>
        <PopoverContent
          w='300px'
          p='0'
          bgColor='white'
          align='end'
          style={{
            maxHeight: '70vh',
            overflowY: 'auto',
            zIndex: 50,
            position: 'relative'
          }}
        >
          <DraggableColumnList
            columns={memoizedColumns.map(col => ({ id: col.accessorKey, label: typeof col.header === 'string' ? col.header : col.accessorKey }))}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnOrderChange={handleColumnOrderChange}
            handleAllColumnVisibilityChange={handleAllColumnVisibilityChange}
          />
        </PopoverContent>
      </Popover>
    </Box>
  ), [columnVisibility, memoizedColumns])

  return (
    <Box display='flex' flexDir='column' h='100%'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <NavBar title='Create Demo Sessions' actions={navbarActions} />
      </Box>
      <Box flexGrow='1' overflow='hidden'>
        <Box h='100%' display='flex' flexDir='column'>
          <Box
            display='flex'
            flexGrow='1'
            overflow='auto'
            style={{
              height: 'calc(100vh - 12rem)',
              display: 'flex',
              flexDirection: 'column'
            }}
            width={{
              lg: 'calc(100vw - var(--chakra-sizes-60))',
              base: '100vw'
            }}
          >
            <Table
              minW='100%'
              borderBottomWidth='1px'
              borderBottomColor='gray.300'
              size='sm'
              sx={{
                'td, th': {
                  py: '2',
                  lineHeight: 'short'
                }
              }}
            >
              <Thead position='sticky' top='0' bgColor='gray.50' zIndex={10}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        px='4'
                        py='3'
                        textAlign='left'
                        fontSize='small'
                        fontWeight='medium'
                        color='gray.500'
                        textTransform='uppercase'
                        bgColor='gray.50'
                        letterSpacing='0.05em'
                        style={{ display: columnVisibility[header.column.id] ?? false ? 'table-cell' : 'none' }}
                      >
                        {header.isPlaceholder ? null : (
                          flexRender(header.column.columnDef.header, header.getContext())
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody bgColor='white' flex='none'>
                {table.getRowModel().rows.map((row) => (
                  <Tr
                    key={row.id}
                    _hover={{
                      bgColor: 'gray.100'
                    }}
                    height='40px'
                    cursor='pointer'
                    onClick={() => navigate(`/session/create-demo/${row.original.sessionId}`)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        px='3'
                        py='1'
                        whiteSpace='nowrap'
                        style={{
                          display: columnVisibility[cell.column.id] ?? false ? 'table-cell' : 'none',
                          verticalAlign: 'middle'
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

