import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  where,
  Query
} from 'firebase/firestore'
import { algoliasearch } from 'algoliasearch'
import { SearchResponse } from '@algolia/client-search'

import { DEFAULT_SELECTED_FILTER_OPTIONS as DEFAULT_SELECTED_FILTER_OPTIONS_DEMO_2, FaceSignSession } from 'pages/Demo2Table'
import { DEFAULT_SELECTED_FILTER_OPTIONS as DEFAULT_SELECTED_FILTER_OPTIONS_CREATE_DEMO } from 'pages/CreateDemoTable'
import { db } from 'controllers/db'
import { TwoFactorT } from 'types/twoFactor'
import { SessionObject } from 'types/internal'
import config from 'src/config'

type PaginationDirection = 'next' | 'back'
type Demo2UpdateCallback = (params: {
  data: FaceSignSession[],
  nextCursor?: string,
  backCursor?: string,
  atStart: boolean,
  atEnd: boolean
}) => void

type CreateDemoUpdateCallback = (params: {
  data: SessionObject[],
  nextCursor?: string,
  backCursor?: string,
  atStart: boolean,
  atEnd: boolean
}) => void

const searchClient = algoliasearch(config.algoliaAppId, config.algoliaSearchKey)

const queryUnsubscribe: () => Promise<void> = async () => { }

const handleAlgoliaSearch = async (
  filterValue: string,
  limitBy: number,
  startTimestamp?: number,
  endTimestamp?: number,
  page?: number,
  onUpdate?: CreateDemoUpdateCallback | Demo2UpdateCallback,
  collectionPath: 'sessions' | 'twoFactorInteractions' = 'twoFactorInteractions'
) => {
  try {
    console.log('algolia sessions search: start')
    const numericFilters = []
    if (typeof startTimestamp === 'number') {
      console.log('algolia sessions search: from timestamp set')
      numericFilters.push(`createdAt>=${startTimestamp}`)
    }
    if (typeof endTimestamp === 'number') {
      console.log('algolia sessions search: to timestamp set')
      numericFilters.push(`createdAt<=${endTimestamp}`)
    }

    const response = await searchClient.search<FaceSignSession>({
      requests: [{
        indexName: config.algoliaCreateDemoSessionsIndex,
        query: filterValue,
        page,
        numericFilters: numericFilters.length > 0 ? numericFilters : undefined
      }]
    })
    console.log('algolia sessions search: result', response)

    const result = response.results[0] as SearchResponse
    const hits = result?.hits || []
    const totalHits = result?.nbHits || 0
    console.log('algolia sessions search: result', totalHits)

    const ids: string[] = hits.map((hit) => hit.id as string)
    const fullData = await fetchFullData(ids, collectionPath)

    const hasNextPage = totalHits > limitBy
    const nextCursor = hasNextPage ? '1' : undefined

    if (collectionPath === 'sessions') {
      (onUpdate as CreateDemoUpdateCallback)({
        data: fullData as SessionObject[],
        nextCursor,
        backCursor: undefined,
        atStart: true,
        atEnd: !hasNextPage
      })
    } else {
      (onUpdate as Demo2UpdateCallback)({
        data: fullData as FaceSignSession[],
        nextCursor,
        backCursor: undefined,
        atStart: true,
        atEnd: !hasNextPage
      })
    }

  } catch (error) {
    console.error('Algolia search error:', error)
    onUpdate?.({
      data: [],
      nextCursor: undefined,
      backCursor: undefined,
      atStart: true,
      atEnd: true
    })
  }

  return () => { }
}

const fetchFullData = async (ids: string[], collectionPath: 'sessions' | 'twoFactorInteractions'): Promise<FaceSignSession[] | SessionObject[]> => {
  try {
    if (ids.length === 0) {
      return []
    }
    const dbCollection = collection(db, collectionPath)
    const q = query(dbCollection, where('id', 'in', ids))
    const querySnapshot = await getDocs(q)

    const fullData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    })) as FaceSignSession[] | SessionObject[]

    return fullData
  } catch (error) {
    console.error('Error fetching full data from Firebase:', error)
    return []
  }
}

export const getDemo2SessionsWithCursor = async (
  limitBy: number,
  direction: PaginationDirection,
  onError: (v: string) => void,
  cursor?: string,
  from?: Date,
  to?: Date,
  filterValue?: string,
  sortedBy: 'dateTime' | 'email' | 'userName' = 'dateTime',
  sortDirection: 'asc' | 'desc' = 'desc',
  filters: { [key: string]: Set<string> } = DEFAULT_SELECTED_FILTER_OPTIONS_DEMO_2,
  onUpdate?: Demo2UpdateCallback
): Promise<() => void> => {
  if (queryUnsubscribe) queryUnsubscribe()

  if (filterValue) {
    const startTimestamp = from?.setHours(0, 0, 0, 0)
    const endTimestamp = to?.setHours(23, 59, 59, 999)
    return handleAlgoliaSearch(filterValue, limitBy, startTimestamp, endTimestamp, 0, onUpdate, 'twoFactorInteractions')
  }

  const sortField = sortedBy === 'dateTime' ? 'createdAt' : sortedBy
  const startTimestamp = from?.setHours(0, 0, 0, 0)
  const endTimestamp = to ? new Date(to).setHours(23, 59, 59, 999) : undefined

  let q = query(
    collection(db, 'twoFactorInteractions'),
    orderBy(sortField, sortDirection),
    orderBy('id', sortDirection),
    limit(limitBy)
  )

  if (startTimestamp !== undefined) {
    q = query(q, where('createdAt', '>=', startTimestamp))
  }
  if (endTimestamp !== undefined) {
    q = query(q, where('createdAt', '<=', endTimestamp))
  }

  const applyFilters = (currentQuery: Query): Query => {
    let newQuery = currentQuery

    if (filters.avatar.size > 0 && filters.avatar.size !== DEFAULT_SELECTED_FILTER_OPTIONS_DEMO_2.avatar.size) {
      newQuery = query(newQuery, where('avatar', 'in', Array.from(filters.avatar)))
    }

    const photoOptions = Array.from(filters.photoThumbnail)
    const hasPhoto = photoOptions.includes('Has photo')
    if (hasPhoto) {
      newQuery = query(
        newQuery,
        where('userAvatarUrl', '>', ''),
        orderBy('userAvatarUrl', 'desc')
      )
    }

    const applyBooleanFilter = (field: string, values: Set<string>) => {
      if (values.size === 1) {
        const value = values.has(field === 'isNotNew' ? 'No' : 'Yes') ? true : false
        newQuery = query(newQuery, where(field, '==', value))
      }
    }

    applyBooleanFilter('isNotNew', filters.isNewUser)
    applyBooleanFilter('isDeepfake', filters.isDeepfake)
    applyBooleanFilter('livenessDetected', filters.isLivePerson)
    applyBooleanFilter('completed', filters.sessionCompleted)

    if (filters.browser.size > 0 && filters.browser.size != DEFAULT_SELECTED_FILTER_OPTIONS_DEMO_2.browser.size) {
      newQuery = query(
        newQuery,
        where('deviceDetails.browser', 'in', Array.from(filters.browser))
      )
    }
    if (filters.platform.size > 0 && filters.platform.size != DEFAULT_SELECTED_FILTER_OPTIONS_DEMO_2.platform.size) {
      newQuery = query(
        newQuery,
        where('deviceDetails.platform', 'in', Array.from(filters.platform))
      )
    }

    return newQuery
  }

  q = applyFilters(q)

  if (cursor) {
    const cursorDoc = await getDoc(doc(db, 'twoFactorInteractions', cursor))
    if (cursorDoc.exists()) {
      if (direction === 'next') {
        q = query(q, startAfter(cursorDoc))
      } else {
        q = query(q, endBefore(cursorDoc), limitToLast(limitBy))
      }
    }
  }

  return onSnapshot(q, async (snapshot) => {
    const docs = snapshot.docs
    if (docs.length === 0) {
      onUpdate?.({
        data: [],
        nextCursor: undefined,
        backCursor: undefined,
        atStart: true,
        atEnd: true
      })
      return
    }

    const data = docs.map((doc) => ({
      id: doc.id,
      ...doc.data() as FaceSignSession
    })) as FaceSignSession[]

    const firstDoc = docs[0]
    const lastDoc = docs[docs.length - 1]

    const buildBaseQuery = () => {
      let base = query(
        collection(db, 'twoFactorInteractions'),
        orderBy(sortField, sortDirection),
        orderBy('id', sortDirection)
      )
      if (startTimestamp !== undefined) {
        base = query(base, where('createdAt', '>=', startTimestamp))
      }
      if (endTimestamp !== undefined) {
        base = query(base, where('createdAt', '<=', endTimestamp))
      }
      return applyFilters(base)
    }

    const prevQuery = query(
      buildBaseQuery(),
      endBefore(firstDoc.data()[sortField]),
      limitToLast(1)
    )

    const nextQuery = query(
      buildBaseQuery(),
      startAfter(lastDoc.data()[sortField]),
      limit(1)
    )

    const [prevSnapshot, nextSnapshot] = await Promise.all([
      getDocs(prevQuery),
      getDocs(nextQuery)
    ])

    const atStart = prevSnapshot.empty
    const atEnd = nextSnapshot.empty

    onUpdate?.({
      data,
      nextCursor: lastDoc?.id,
      backCursor: firstDoc?.id,
      atStart,
      atEnd
    })
  }, (error) => {
    console.error('Firestore snapshot listener error:', error)
    if (error.code === 'failed-precondition') {
      onError(error.message)
    }
  })
}

export const getDemo2SessionById = async (
  sessionId: string
): Promise<TwoFactorT.RecapT | undefined> => {
  try {
    const docRef = doc(db, 'twoFactorInteractions', sessionId)
    const docSnap = await getDoc(docRef)

    return docSnap.exists()
      ? docSnap.data() as TwoFactorT.RecapT
      : undefined
  } catch (error) {
    console.error('Error fetching session:', error)
    return undefined
  }
}

export const getCreateDemoSessionById = async (
  sessionId: string
): Promise<SessionObject & { userAvatarUrl: string } | undefined> => {
  try {
    const docRef = doc(db, 'sessions', sessionId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data() as SessionObject
      const bestPhotoIndex = data.aiAnalysisFirst?.bestPhotoIndex ?? 0
      const screenshotsSnap = await getDocs(
        query(
          collection(db, 'sessions', data.id, 'screenshots'),
          orderBy('createdAt', 'asc')
        )
      )

      return {
        ...data,
        userAvatarUrl: screenshotsSnap.docs[bestPhotoIndex]?.get('url') || null
      }
    }
    return
  } catch (error) {
    console.error('Error fetching session:', error)
    return undefined
  }
}

export const getCreateDemoSessionsWithCursor = async (
  limitBy: number,
  direction: PaginationDirection,
  onError: (v: string) => void,
  cursor?: string,
  from?: Date,
  to?: Date,
  filterValue?: string,
  sortedBy: 'dateTime' | 'email' | 'userName' = 'dateTime',
  sortDirection: 'asc' | 'desc' = 'desc',
  filters: { [key: string]: Set<string> } = DEFAULT_SELECTED_FILTER_OPTIONS_CREATE_DEMO,
  onUpdate?: CreateDemoUpdateCallback
): Promise<() => void> => {
  if (queryUnsubscribe) queryUnsubscribe()

  if (filterValue) {
    const startTimestamp = from?.setHours(0, 0, 0, 0)
    const endTimestamp = to?.setHours(23, 59, 59, 999)
    return handleAlgoliaSearch(filterValue, limitBy, startTimestamp, endTimestamp, 0, onUpdate, 'sessions')
  }

  const sortField = sortedBy === 'dateTime' ? 'createdAt' : sortedBy
  const startTimestamp = from?.setHours(0, 0, 0, 0)
  const endTimestamp = to ? new Date(to).setHours(23, 59, 59, 999) : undefined

  let q = query(
    collection(db, 'sessions'),
    orderBy(sortField, sortDirection),
    orderBy('id', sortDirection),
    limit(limitBy)
  )

  if (startTimestamp !== undefined) {
    q = query(q, where('createdAt', '>=', startTimestamp))
  }
  if (endTimestamp !== undefined) {
    q = query(q, where('createdAt', '<=', endTimestamp))
  }

  if (cursor) {
    const cursorDoc = await getDoc(doc(db, 'sessions', cursor))
    if (cursorDoc.exists()) {
      if (direction === 'next') {
        q = query(q, startAfter(cursorDoc))
      } else {
        q = query(q, endBefore(cursorDoc), limitToLast(limitBy))
      }
    }
  }

  const applyFilters = (currentQuery: Query): Query => {
    let newQuery = currentQuery

    const applyBooleanFilter = (field: string, values: Set<string>) => {
      if (values.size === 1) {
        const value = values.has(field === 'modulesData.emailVerification.isNotNew' ? 'No' : 'Yes') ? true : false
        newQuery = query(newQuery, where(field, '==', value))
      }
    }

    applyBooleanFilter('modulesData.emailVerification.isNotNew', filters.isNewUser)
    applyBooleanFilter('modulesData.emailVerification.livenessDetected', filters.isLivePerson)

    if (filters.browser.size > 0 && filters.browser.size != DEFAULT_SELECTED_FILTER_OPTIONS_CREATE_DEMO.browser.size) {
      newQuery = query(
        newQuery,
        where('device.browser', 'in', Array.from(filters.browser))
      )
    }

    if (filters.platform.size > 0 && filters.platform.size != DEFAULT_SELECTED_FILTER_OPTIONS_CREATE_DEMO.platform.size) {
      newQuery = query(
        newQuery,
        where('device.platform', 'in', Array.from(filters.platform))
      )
    }


    if (filters.status.size > 0 && filters.status.size != DEFAULT_SELECTED_FILTER_OPTIONS_CREATE_DEMO.status.size) {
      newQuery = query(
        newQuery,
        where('status', 'in', Array.from(filters.status))
      )
    }

    return newQuery
  }

  q = applyFilters(q)

  return onSnapshot(q, async (snapshot) => {
    const docs = snapshot.docs
    if (docs.length === 0) {
      onUpdate?.({
        data: [],
        nextCursor: undefined,
        backCursor: undefined,
        atStart: true,
        atEnd: true
      })
      return
    }
    const data = await Promise.all(docs.map(async (doc) => {
      const sessionData = doc.data() as SessionObject
      const bestPhotoIndex = sessionData.aiAnalysisFirst?.bestPhotoIndex ?? 0
      const screenshotsSnap = await getDocs(
        query(
          collection(db, 'sessions', doc.id, 'screenshots'),
          orderBy('createdAt', 'asc')
        )
      )

      return {
        ...sessionData,
        userAvatarUrl: screenshotsSnap.docs[bestPhotoIndex]?.get('url') || null
      }
    }))


    const firstDoc = docs[0]
    const lastDoc = docs[docs.length - 1]

    const buildBaseQuery = () => {
      let base = query(
        collection(db, 'sessions'),
        orderBy(sortField, sortDirection),
        orderBy('id', sortDirection)
      )
      if (startTimestamp !== undefined) {
        base = query(base, where('createdAt', '>=', startTimestamp))
      }
      if (endTimestamp !== undefined) {
        base = query(base, where('createdAt', '<=', endTimestamp))
      }
      return base
    }

    const prevQuery = query(
      buildBaseQuery(),
      endBefore(firstDoc.data()[sortField]),
      limitToLast(1)
    )

    const nextQuery = query(
      buildBaseQuery(),
      startAfter(lastDoc.data()[sortField]),
      limit(1)
    )

    const [prevSnapshot, nextSnapshot] = await Promise.all([
      getDocs(prevQuery),
      getDocs(nextQuery)
    ])

    const atStart = prevSnapshot.empty
    const atEnd = nextSnapshot.empty

    onUpdate?.({
      data,
      nextCursor: lastDoc?.id,
      backCursor: firstDoc?.id,
      atStart,
      atEnd
    })
  }, (error) => {
    console.error('Firestore snapshot listener error:', error)
    if (error.code === 'failed-precondition') {
      onError(error.message)
    }
  })
}
