import { Radio, RadioGroup, Stack, VStack } from '@chakra-ui/react'
import Label from 'components/Label'
import { FC } from 'react'
import { DBT } from 'types/internal'

interface Props {
  value: DBT.TRANSCRIBER
  onChange: (v: DBT.TRANSCRIBER) => void
}

const TranscriberSelect: FC<Props> = ({ value, onChange }) => {
  return (
    <VStack align='start' spacing={4} w='full'>
      <Label>Transcription service</Label>
      <RadioGroup onChange={onChange} value={value} size='sm'>
        <Stack direction='row'>
          <Radio value={DBT.TRANSCRIBER.DEEPGRAM}>
            {DBT.TRANSCRIBER.DEEPGRAM}
          </Radio>
          <Radio value={DBT.TRANSCRIBER.GLADIA}>{DBT.TRANSCRIBER.GLADIA}</Radio>
          <Radio value={DBT.TRANSCRIBER.SPEECHMATICS}>
            {DBT.TRANSCRIBER.SPEECHMATICS}
          </Radio>
          <Radio value={DBT.TRANSCRIBER.OPENAI}>{DBT.TRANSCRIBER.OPENAI}</Radio>
          <Radio value={DBT.TRANSCRIBER.GOOGLE} isDisabled>
            {DBT.TRANSCRIBER.GOOGLE}
          </Radio>
        </Stack>
      </RadioGroup>
    </VStack>
  )
}

export default TranscriberSelect
