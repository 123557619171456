import { FC } from 'react'
import { VStack, Divider } from '@chakra-ui/react'
import _ from 'lodash'
import { DBT } from 'types/internal'
import SubPrompt from 'pages/demo2/SubPrompt'
import SimpleTextarea from 'shared/components/SimpleTextarea'
import EditableTranslates from 'components/EditableTranslates'
import TemperatureInput from 'components/TemperatureInput'

type Props = {
  conf: DBT.AIPromptConfT | null
  setConf: (c: DBT.AIPromptConfT) => void
}

const EmailVerificationModule: FC<Props> = ({ conf, setConf }) => {
  const onSubPromptChange = (sp: DBT.SubPromptT, state: DBT.Demo2Stage) => {
    if (conf) {
      const prompts = conf.prompts || {}
      setConf({
        ...conf,
        prompts: {
          ...prompts,
          [state]: sp
        }
      })
    }
  }

  const onChange = (params: Partial<DBT.AIPromptConfT>) => {
    if (conf) {
      setConf({
        ...conf,
        ...params
      })
    }
  }

  const renderInitialPhrase = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={2}>
        <SimpleTextarea
          label='Initial phrase'
          value={_.get(conf, 'initialPhrase', '')}
          onChange={v => onChange({ initialPhrase: v })}
        />
        <EditableTranslates
          phrase={_.get(conf, 'initialPhrase', '')}
          value={conf?.initialPhraseTranslates || {}}
          onChange={t => onChange({ initialPhraseTranslates: t })}
        />
      </VStack>
    )
  }

  const renderFinalPhrase = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={2}>
        <SimpleTextarea
          label='Final phrase'
          value={_.get(conf, 'finalPhrase', '')}
          onChange={v => onChange({ finalPhrase: v })}
        />
        <EditableTranslates
          phrase={_.get(conf, 'finalPhrase', '')}
          value={conf?.finalPhraseTranslates || {}}
          onChange={t => onChange({ finalPhraseTranslates: t })}
        />
      </VStack>
    )
  }

  const renderTemperatureInput = () => {
    if (!conf) return null
    const value = conf.temperature || 0
    return (
      <TemperatureInput
        value={value}
        onChange={value => onChange({ temperature: value })}
      />
    )
  }

  return (
    <VStack w='full'>
      {_.map(DBT.Demo2Stage, s => (
        <VStack key={s} w='full' align={'start'}>
          <Divider />
          <SubPrompt
            title={s}
            sp={_.get(conf, ['prompts', s])}
            onChange={sp => onSubPromptChange(sp, s)}
          />
        </VStack>
      ))}
      {renderInitialPhrase()}
      {renderFinalPhrase()}
      {renderTemperatureInput()}
    </VStack>
  )
}

export default EmailVerificationModule
