import { FC, useMemo } from 'react'
import { Button, Flex, Text, VStack, Tag } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import Label from 'components/Label'
import { DBT } from 'types/internal'
import _ from 'lodash'
import { useSelector } from 'model/hooks'

type Props = {
  value?: string
  onSelect: (avatarId: string) => void
}

const AvatarSelect: FC<Props> = ({ value, onSelect }) => {
  const avatarsRaw = useSelector(state => state.avatars)

  const avatars = useMemo(() => {
    return _.orderBy(avatarsRaw, 'createdAt', 'asc')
  }, [avatarsRaw])

  const selectedAvatar = useMemo(() => {
    return _.find(avatars, a => a.id === value)
  }, [avatars, value])

  const renderAvatarOption = (avatar: DBT.AvatarT) => {
    let badgeColor
    switch (avatar.gender) {
      case 'male':
        badgeColor = 'blue'
        break
      case 'female':
        badgeColor = 'pink'
        break
      default:
        badgeColor = 'gray'
    }

    return (
      <MenuItem
        key={avatar.id}
        value={avatar.id}
        onClick={() => onSelect(avatar.id)}
      >
        <Flex justifyContent='space-between' alignItems='center'>
          <Flex alignItems='center' gap={4}>
            <img
              src={avatar.imageUrl}
              alt={avatar.name}
              width={100}
              height={50}
            />
            <VStack align={'start'} spacing={0}>
              <Text fontSize={'sm'}>{avatar.name}</Text>
              <Text fontSize='xs' color='gray.500'>
                ID: {avatar.id}
              </Text>
              <Tag size='sm' variant='outline' colorScheme={badgeColor}>
                {avatar.gender}
              </Tag>
            </VStack>
          </Flex>
        </Flex>
      </MenuItem>
    )
  }

  return (
    <VStack w='full' align={'flex-start'}>
      <Label>Avatar ID</Label>
      <Flex alignItems='center' gap={2} w='full'>
        <Menu placement='bottom-start'>
          <MenuButton
            as={Button}
            size='sm'
            variant={'outline'}
            rightIcon={<ChevronDownIcon />}
            w='full'
            h='auto'
          >
            {selectedAvatar
              ? renderAvatarOption(selectedAvatar)
              : 'Select avatar'}
          </MenuButton>
          <MenuList maxH={'md'} overflowY='auto'>
            {avatars.map(renderAvatarOption)}
          </MenuList>
        </Menu>
      </Flex>
    </VStack>
  )
}

export default AvatarSelect
