import { Textarea, Button, VStack, useToast } from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'
import { useState, FC, useEffect, useRef } from 'react'
import Label from 'components/Label'
import VoiceSelect from 'pages/settings/VoiceSelect'
import { Unsubscribe } from 'firebase/firestore'
import { dbSubscribeToFlexConf, dbUpdateFlexConf } from 'controllers/flexConf'
import { DBT } from 'types/internal'
import _ from 'lodash'
import TemperatureInput from 'components/TemperatureInput'
import JsonSchemeInput from 'components/JsonSchemeInput'
import NavBar from 'components/NavBar'
import AvatarSelect from 'pages/settings/AvatarSelect'

const SystemPrompt: FC = () => {
  const [conf, setConf] = useState<DBT.AIPromptConfT | null>(null)
  const [savedConf, setSavedConf] = useState<DBT.AIPromptConfT | null>(null)
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const toast = useToast()

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    unsubscribeRef.current = dbSubscribeToFlexConf(conf => {
      setConf(conf)
      setSavedConf(conf)
    })
  }, [])

  const onSave = () => {
    if (conf) {
      try {
        const newConf = { ...conf }
        if (newConf.jsonScheme) {
          JSON.parse(newConf.jsonScheme)
        }
        dbUpdateFlexConf(conf)
      } catch (e) {
        toast({
          title: 'Error',
          description: 'Failed to save configuration, check JSON scheme',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const onVoiceChange = (voiceId: string) => {
    console.log('onVoiceChange', voiceId)
    if (conf) {
      setConf({ ...conf, voiceId })
    }
  }

  const onAvatarChange = (avatarId: string) => {
    console.log('onAvatarChange', avatarId)
    if (conf) {
      setConf({ ...conf, avatarId })
    }
  }

  const onChangePrompt = (prompt: string) => {
    if (conf) {
      setConf({ ...conf, prompt })
    }
  }

  const onJsonSchemeChange = (jsonScheme: string) => {
    if (conf) {
      setConf({ ...conf, jsonScheme })
    }
  }

  const renderTemperatureInput = () => {
    if (!conf) return null
    const value = conf.temperature || 0
    return (
      <TemperatureInput
        value={value}
        onChange={value => setConf({ ...conf, temperature: value })}
      />
    )
  }

  const actions = (
    <Button
      variant={'outline'}
      colorScheme='white'
      onClick={onSave}
      isDisabled={_.isEqual(conf, savedConf)}
      size='sm'
    >
      Save
    </Button>
  )

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Flexible App'} actions={actions} />
      <VStack w='full' overflow={'auto'} p={6}>
        <VStack w='full' spacing={6}>
          <VoiceSelect value={conf?.voiceId} onSelect={onVoiceChange} />
          <AvatarSelect value={conf?.avatarId} onSelect={onAvatarChange} />
          <VStack w='full' align={'flex-start'} spacing={2}>
            <Label>Prompt</Label>
            <Textarea
              placeholder='Enter prompt here'
              value={_.get(conf, 'prompt', '')}
              onChange={e => onChangePrompt(e.target.value)}
              fontSize={'sm'}
              minH='unset'
              overflow='hidden'
              w='100%'
              resize='none'
              minRows={5}
              as={ResizeTextarea}
            />
          </VStack>
          <JsonSchemeInput
            value={conf?.jsonScheme || ''}
            onChange={onJsonSchemeChange}
          />
          {renderTemperatureInput()}
        </VStack>
      </VStack>
    </VStack>
  )
}

export default SystemPrompt
