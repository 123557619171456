import { useCallback, useEffect, useState, useRef } from 'react'
import { Box, Text, Center, Link } from '@chakra-ui/react'
import _ from 'lodash'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { DateRange } from 'react-day-picker'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'
import { Button } from 'components/ui/Button'
import { Demo2SessionsTable } from 'pages/sessions/Demo2SessionsTable'
import { getDemo2SessionsWithCursor } from 'controllers/sessions'

export type FaceSignSession = {
  sessionId: string
  dateTime: string
  email: string | null
  userName: string
  env: string,
  avatar: string
  appVersion: string
  verificationId: string
  platform: string
  browser: string
  location: string
  language: string
  photoThumbnail: string
  isNewUser: boolean
  isLivePerson: boolean
  ageRange: string
  source: string
  sessionCompleted: boolean
  errorMessage: string | null
}

export const DEFAULT_FILTER_OPTIONS = {
  avatar: new Set(['heygen', 'custom', 'd-id', 'azure']),
  photoThumbnail: new Set(['Has photo']),
  isNewUser: new Set(['Yes', 'No']),
  isDeepfake: new Set(['Yes', 'No']),
  isLivePerson: new Set(['Yes', 'No']),
  sessionCompleted: new Set(['Yes', 'No']),
  browser: new Set(['Safari', 'Chrome', 'Edge', 'Firefox']),
  platform: new Set(
    ['Microsoft Windows', 'Apple Mac', 'iPhone', 'Android']
  )
}

export const DEFAULT_SELECTED_FILTER_OPTIONS = {
  avatar: new Set(['heygen', 'custom', 'd-id', 'azure']),
  photoThumbnail: new Set([]),
  isNewUser: new Set(['Yes', 'No']),
  isDeepfake: new Set(['Yes', 'No']),
  isLivePerson: new Set(['Yes', 'No']),
  sessionCompleted: new Set(['Yes', 'No']),
  browser: new Set(['Safari', 'Chrome', 'Edge', 'Firefox']),
  platform: new Set(
    ['Microsoft Windows', 'Apple Mac', 'iPhone', 'Android']
  )
}

export default function Demo2Table() {
  const [data, setData] = useState<FaceSignSession[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [pageSize, setPageSize] = useState<number>(20)
  const [dateRange, setDateRange] = useState<DateRange>()
  const [cursors, setCursors] = useState<{
    next?: string,
    previous?: string,
    atStart?: boolean,
    atEnd?: boolean
  }>({})
  const [filterValue, setFilterValue] = useState<string>('')
  const unsubscribeRef = useRef<() => void>(() => { })
  const [sortingSettings, setSortingSettings] = useState<{
    id: 'dateTime' | 'email' | 'userName',
    desc: boolean
  }>({
    id: 'dateTime',
    desc: true
  })
  const [filterOptions, setFilterOptions] = useState<{ [key: string]: Set<string> }>(DEFAULT_SELECTED_FILTER_OPTIONS)

  useEffect(() => {
    setCursors({})
  }, [sortingSettings.id, sortingSettings.desc])

  useEffect(() => {
    const fetchData = async () => {
      await getData(sortingSettings.desc ? 'next' : 'back', {})
    }

    if (!loading) {
      setLoading(true)
      fetchData()
    }

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
    }
  }, [pageSize, dateRange, filterValue, sortingSettings, filterOptions])

  const getData = async (
    direction: 'back' | 'next',
    cursorsLocal: {
      next?: string,
      previous?: string,
      atStart?: boolean,
      atEnd?: boolean
    }
  ) => {
    setError(null)
    const cursor = direction === 'next' ? cursorsLocal.next : cursorsLocal.previous

    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }

    try {
      const unsubscribe = await getDemo2SessionsWithCursor(
        pageSize,
        direction,
        (v: string) => setError(v),
        cursor,
        dateRange?.from,
        dateRange?.to,
        filterValue,
        sortingSettings.id,
        sortingSettings.desc ? 'desc' : 'asc',
        filterOptions,
        ({ data, nextCursor, backCursor, atStart, atEnd }) => {
          const formattedSessions = formatSessionsObject(data)
          setData(formattedSessions)
          setCursors({
            next: nextCursor,
            previous: backCursor,
            atStart,
            atEnd
          })
          setLoading(false)
        }
      )

      unsubscribeRef.current = unsubscribe
    } catch (error) {
      console.error('Error setting up listener:', error)
      setError('Error initializing data connection')
      setLoading(false)
    }
  }

  const handleDateRangeChange = (date: DateRange | undefined) => {
    setCursors({})
    setDateRange(date)
  }

  const handleFilterChange = ({ id, selectedValues }: { id: string, selectedValues: Set<string> }) => {
    setCursors({})
    setFilterOptions(prev => ({ ...prev, [id]: selectedValues }))
  }

  const handleSortClick = (v: 'dateTime' | 'email' | 'userName') => {
    if (sortingSettings.id === v) {
      setSortingSettings(prev => ({
        ...prev,
        desc: !prev.desc
      }))
    } else {
      setSortingSettings({
        id: v,
        desc: true
      })
    }
  }

  const handleFilterReset = () => {
    setCursors({})
    setFilterOptions(DEFAULT_SELECTED_FILTER_OPTIONS)
  }

  const formatSessionsObject = useCallback((sObject: unknown[]): FaceSignSession[] =>
    _.map(sObject, s => ({
      sessionId: _.get(s, 'id', ''),
      dateTime: _.has(s, 'createdAt') ? new Date(_.get(s, 'createdAt')).toUTCString() : '',
      email: _.get(s, 'email', ''),
      userName: _.get(s, 'userName', ''),
      env: _.get(s, 'isDev', false) ? 'INT' : 'PROD',
      avatar: _.get(s, 'avatar', ''),
      appVersion: _.get(s, 'version', ''),
      verificationId: _.get(s, 'verificationId', ''),
      platform: _.get(s, 'deviceDetails.platform', ''),
      browser: _.get(s, 'deviceDetails.browser', ''),
      location: _.get(s, 'location.city.names.en') &&
        _.get(s, 'location.country.names.en') ?
        `${_.get(s, 'location.city.names.en')}, ${_.get(s, 'location.country.names.en')}` :
        _.get(s, 'location.city.names.en') || _.get(s, 'location.country.names.en') || '',
      language: _.get(s, 'lang', ''),
      photoThumbnail: _.get(s, 'userAvatarUrl', ''),
      isNewUser: !_.get(s, 'isNotNew'),
      isLivePerson: _.get(s, 'livenessDetected', false),
      ageRange: `${_.get(s, 'aiAnalysisFirst.ageMin', '?')} – ${_.get(s, 'aiAnalysisFirst.ageMax', '?')}`,
      source: '',
      sessionCompleted: _.get(s, 'completed', false),
      errorMessage: '',
      userId: _.get(s, 'userId', '')
    })), [data])

  if (error) {
    return (
      <Center height='100vh' px={4} textAlign='center' w='full'>
        <Box maxW='container.md'>
          <Text fontSize='lg'>
            {error.split(/(https?:\/\/[^\s]+)/g).map((part, i) =>
              part.match(/^https?:\/\//) ? (
                <Link key={i} href={part} isExternal color='blue.500'>
                  {part}
                </Link>
              ) : (
                part
              )
            )}
          </Text>
        </Box>
      </Center>
    )
  }

  return (
    <Box h='full' display='flex' flexDir='column'>
      <Box flexGrow='1' overflow='hidden'>
        <Demo2SessionsTable
          data={data}
          loading={loading}
          setDateRange={handleDateRangeChange}
          pageSize={pageSize}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          sortingSettings={sortingSettings}
          handleSortClick={handleSortClick}
          handleFilterChange={handleFilterChange}
          allFilterOptions={DEFAULT_FILTER_OPTIONS}
          filterOptions={filterOptions}
          handleFilterReset={handleFilterReset}
        />
      </Box>
      <Box borderTopColor='gray.200' px={{ lg: '4', base: '6' }} py='3'>
        <Box maxW='100%' overflowX='auto'>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' flexGrow={0} flexShrink={0} alignItems='center' gap='2'>
              <Button
                variant='outline'
                size='sm'
                onClick={() => getData('back', cursors)}
                disabled={loading || !!error || cursors.atStart}
              >
                <ChevronLeftIcon h='4' w='4' />
              </Button>
              <Button
                variant='outline'
                size='sm'
                onClick={() => getData('next', cursors)}
                disabled={loading || !!error || cursors.atEnd}
              >
                <ChevronRightIcon h='4' w='4' />
              </Button>
            </Box>
            <Box width='fit-content'>
              <Select
                value={pageSize.toString()}
                onValueChange={(value) => {
                  setPageSize(Number(value))
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize.toString()}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}