import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  FormControl,
  VStack,
  ModalCloseButton,
  Input,
  Text,
  HStack,
  RadioGroup,
  Radio,
  useToast
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import Label from 'components/Label'
import { dbCreateVegasUser } from 'controllers/vegas'
import JsonInput from 'components/JsonInput'
import CodeEditor from 'components/CodeEditor'
import CodeEditorStandalone from 'components/CodeEditorStandalone'
import { TwoFactorT } from 'types/twoFactor'
import { DBT } from 'types/internal'
import SimpleInput from 'shared/components/SimpleInput'
import { dbDeleteAvatar, dbSaveAvatar } from 'controllers/settings'
import FilesPickerButton from 'components/FilesPickerButton'
import ImagePaste from 'components/ImagePaste'
import { FileWithPath } from 'react-dropzone/.'
import { saveFileObject } from 'controllers/storage'

export interface IEditAvatarModal {
  open: (avatar?: DBT.AvatarT) => void
}

const EditAvatarModal: ForwardRefRenderFunction<IEditAvatarModal> = (
  _props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isNew, setIsNew] = useState(false)
  const [avatar, setAvatar] = useState<Partial<DBT.AvatarT>>({})
  const [processingFiles, setProcessingFiles] = useState(false)
  const toast = useToast()

  useImperativeHandle(ref, () => ({
    open: (avatar: DBT.AvatarT) => {
      setAvatar(avatar || {})
      setIsNew(_.isNil(avatar))
      onOpen()
    }
  }))

  const onChange = (params: Partial<DBT.AvatarT>) => {
    setAvatar({
      ...avatar,
      ...params
    })
  }

  const resizeImage = async (file: FileWithPath | File): Promise<File> => {
    const image = await createImageBitmap(file)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const maxDimension = 1024
    const scale = Math.min(
      maxDimension / image.width,
      maxDimension / image.height,
      1
    )
    canvas.width = image.width * scale
    canvas.height = image.height * scale
    if (ctx) {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    }
    return new Promise<File>(resolve => {
      canvas.toBlob(blob => {
        resolve(new File([blob!], file.name, { type: file.type }))
      }, file.type)
    })
  }

  const uploadFileToStorage = async (f: FileWithPath | File) => {
    if (avatar && avatar.id) {
      const fileExt = _.last(_.split(f.name, '.'))
      const filename = `${avatar.id}_${generateId()}.${fileExt}`
      const storagePath = `avatars/${filename}`
      const storageFile = await saveFileObject(f, storagePath)
      return storageFile
    }
  }

  const onFilesPicked = async (files: File[] | FileWithPath[]) => {
    console.log('on files picked', files)

    if (files.length > 0) {
      try {
        const f = files[0]
        setProcessingFiles(true)
        const resizedFile = await resizeImage(f)

        const storageFile = await uploadFileToStorage(resizedFile)
        console.log('storageFile', storageFile)
        const url = storageFile?.url
        if (url) {
          onChange({ imageUrl: url })
        }
        setProcessingFiles(false)
      } catch (e) {
        console.error(e)
        setProcessingFiles(false)
        toast({
          title: 'Error',
          description: 'Failed to upload files, check console logs',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const renderContent = () => {
    return (
      <VStack w='full' align='start' spacing={6}>
        <SimpleInput
          label='HeyGen ID'
          value={avatar.id || ''}
          onChange={v => onChange({ id: v })}
        />
        <SimpleInput
          label='Name'
          value={avatar.name || ''}
          onChange={v => onChange({ name: v })}
        />

        <VStack spacing={1} align={'start'}>
          <Label>Gender</Label>
          <RadioGroup
            value={avatar.gender || 'unknown'}
            onChange={v =>
              onChange({ gender: v as 'male' | 'female' | 'unknown' })
            }
          >
            <HStack spacing={4}>
              <Radio size='sm' value='male'>
                Male
              </Radio>
              <Radio size='sm' value='female'>
                Female
              </Radio>
              <Radio size='sm' value='unknown'>
                Unknown
              </Radio>
            </HStack>
          </RadioGroup>
        </VStack>

        <VStack spacing={2} align={'start'} w='full'>
          <Label>Image URL</Label>
          <SimpleInput
            value={avatar.imageUrl || ''}
            onChange={v => onChange({ imageUrl: v })}
            placeholder='Enter image URL'
          />

          <FilesPickerButton
            options={{
              accept: { 'image/jpeg': [], 'image/png': [], 'image/heic': [] }
            }}
            onFilesPicked={onFilesPicked}
            isLoading={processingFiles}
          />
          <ImagePaste onSelect={onFilesPicked} />

          <VStack />
        </VStack>
      </VStack>
    )
  }

  const onSave = async () => {
    console.log('onSave')
    const id = _.get(avatar, 'id')
    if (id) {
      dbSaveAvatar(avatar as DBT.AvatarT)
      onClose()
    }
  }

  const onDelete = async () => {
    console.log('onSave')
    const id = _.get(avatar, 'id')
    if (id) {
      dbDeleteAvatar(id)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNew ? 'Add avatar' : 'Edit avatar'}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody pt={4}>
          <VStack w='full' spacing={6}>
            <VStack w='full' spacing={8}>
              {renderContent()}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justify={'space-between'} w='full'>
            <Button
              variant={'link'}
              colorScheme='red'
              onClick={onDelete}
              size='sm'
              isDisabled={isNew}
            >
              Delete
            </Button>
            <HStack spacing={6}>
              <Button
                variant={'link'}
                onClick={onClose}
                size='sm'
                colorScheme='zinc'
              >
                Cancel
              </Button>
              <Button variant={'primary'} onClick={onSave} size='sm'>
                {isNew ? 'Add' : 'Save'}
              </Button>
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(EditAvatarModal)
