const conf = {
  backendUrl: 'https://facesign-dev-api.web.app',
  isDev: true,
  apiKey: 'AIzaSyC9Yrb5KsOSLelGbl9ymc-90SsNZCPTkDI',
  authDomain: 'facesignai-dev.firebaseapp.com',
  projectId: 'facesignai-dev',
  storageBucket: 'facesignai-dev.appspot.com',
  messagingSenderId: '511529342332',
  appId: '1:511529342332:web:a48b075c56218f48a240f9',
  algoliaAppId: 'VTOK70EGPB',
  algoliaSearchKey: 'ac2727f7de7255494958d2f97c851641',
  algoliaUsersIndex: 'prod_facesign_users',
  algoliaDemo2SessionsIndex: 'dev_demo2_sessions',
  algoliaCreateDemoSessionsIndex: 'dev_sessions',
  vegasUsersBucket: 'gs://facesignai-dev-vegas-users'
}

module.exports = conf
